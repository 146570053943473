import posthog, { PostHog } from "posthog-js";
import { PostHogProvider as Provider } from "posthog-js/react";
import { ReactNode, useMemo } from "react";

export type PostHogProviderProps = {
  children: ReactNode;
};

const postHogApiKey = import.meta.env.VITE_POSTHOG_API_KEY;

export default function PostHogProvider({ children }: PostHogProviderProps) {
  const postHogClient = useMemo<PostHog | undefined>(() => {
    if (typeof window === "undefined" || postHogApiKey === undefined) {
      return undefined;
    }

    posthog.init(postHogApiKey, { api_host: "/ingest" });

    return posthog;
  }, []);

  return postHogClient === undefined ? children : (
    <Provider client={postHogClient}>{children}</Provider>
  );
}
